a.disabled {
  pointer-events: none;
}

#loading-mask {
  background: #000;
  opacity: 85%;
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  z-index: 1100;
}
#loading-mask .mask-spinner {
  width: 64px;
  z-index: 1065;
  left: 50%;
  top: 50%;
  background: url(../img/loading.gif) no-repeat;
  height: 64px;
  position: absolute;
}

.accordion-button.opened-not-closeable:not(.collapsed) {
  pointer-events: none;
}